import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { useSpring, animated } from 'react-spring';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout/Layout";
import { Button } from "../components/Button";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";
import { slug } from "../helpers";

import small_transcript_orange from "../assets/images/small_transcript_orange.svg";
import orange_chevron from "../assets/images/orange_chevron.svg";

export default ({ data, location, pageContext }) => {
    const [isPlatformCatalogueOpen, setIsPlatformCatalogueOpen] = React.useState(false);
    const spring = useSpring({ opacity: isPlatformCatalogueOpen ? 1 : 0, transform: isPlatformCatalogueOpen ? "translateY(0px)" : "translateY(1000px)" });

    const content = data.allContentfulPlatform.edges;
    const platform = data.allContentfulPlatformCategory.edges;

    const [currentCategory, setCurrentCategory] = React.useState(slug(content[0].node.platform_category[0].categoryTitle));
    const [currentArticle, setCurrentArticle] = React.useState(slug(platform[0].node.platformArticles[0].platformTitle));
    const [currentArticleTitle, setCurrentArticleTitle] = React.useState(platform[0].node.platformArticles[0].platformTitle);

    const currentCategoryAsTitle = platform.find((f) => f.node.platformArticles !== undefined && f.node.platformArticles.some((s) => s.platformTitle === currentArticleTitle)).node.categoryTitle;
    const documentTitle = `${currentCategoryAsTitle} - ${currentArticleTitle}`;

    const toggleCatalogue = () => setIsPlatformCatalogueOpen(isPlatformCatalogueOpen => !isPlatformCatalogueOpen);
    const handleChangeArticle = (category, article) => {
        setCurrentArticle(slug(article));
        setCurrentArticleTitle(article); // Used for getting the article title as un-slugged
        setCurrentCategory(slug(category));

        if (window && document) {
            window.history.pushState({}, "", `${pageContext.locale === 'de' ? '/de' : ''}/platform/${slug(article)}`)
            document.title = documentTitle;
        }

        const scrollToContent = () => {
            const content = document.getElementById("platform-content");
            if (!content) {
                window.requestAnimationFrame(scrollToContent);
            } else {
                content.scrollIntoView({ behavior: "smooth" });
            }
        }
        scrollToContent();
        setIsPlatformCatalogueOpen(false);
    }

    return (
        <Layout
            title={documentTitle}
            transparentNavigation={true}
            location={location}
            language={pageContext.locale}
        >
            <div className="mobile-platform-catalogue-container is-hidden-tablet">
                <animated.div style={spring} className="platform-menu">
                    <PlatformMenu
                        platform={platform}
                        color="orange"
                        currentArticle={currentArticle}
                        currentCategory={currentCategory}
                        handleChangeArticle={handleChangeArticle}
                    />
                </animated.div>

                <PlatformMobileMenu
                    locale={pageContext.locale}
                    onClick={toggleCatalogue}
                    isOpen={isPlatformCatalogueOpen}
                />
            </div>
            <main id="content" style={{ marginTop: "-80px" }}>
                <PlatformHero
                    platform={platform}
                    color="orange"
                    currentArticle={currentArticle}
                    currentCategory={currentCategory}
                    handleChangeArticle={handleChangeArticle}
                    data={data}
                    locale={pageContext.locale}
                />
                <Platform
                    platform={platform}
                    content={content}
                    color="orange"
                    currentArticle={currentArticle}
                    currentCategory={currentCategory}
                    handleChangeArticle={handleChangeArticle}
                    data={data}
                    locale={pageContext.locale}
                />
            </main>
        </Layout>
    )
}

export const PlatformMobileMenu = ({
    locale,
    onClick,
    isOpen,
}) => {
    const isLocaleDe = () => locale === 'de';

    const getTranslation = (key) => {
        if (key === 'mobileMenuTitle') {
            return isLocaleDe() ? 'Plattform-Katalog' : 'platform catalogue';
        }

        return key;
    }

    return (
        <nav className="navbar mobile-platform-catalogue" onClick={onClick} role="navigation" aria-label="platform catalogue">
            <div className="navbar-item">
                <img src={small_transcript_orange} style={{ verticalAlign: "middle" }} alt="" />
            </div>
            <div className="navbar-item">
                <p className="is-orange size-24">{getTranslation('mobileMenuTitle')}</p>
            </div>
            <div className="navbar-item chevron">
                <img src={orange_chevron} style={{ verticalAlign: "middle", transform: isOpen ? "rotate(0deg)" : "rotate(180deg)", transition: "all .5s ease" }} alt="" />
            </div>
        </nav>
    );
};

export const PlatformHero = ({
    platform,
    color,
    currentArticle,
    currentCategory,
    handleChangeArticle,
    data,
    locale
}) => {
    const isLocaleDe = () => locale === 'de';

    const getTranslation = (key) => {
        if (key === 'heroTitlePart1') {
            return isLocaleDe() ? 'Das' : 'things';
        }
        if (key === 'heroTitlePart2') {
            return isLocaleDe() ? 'was' : 'that';
        }
        if (key === 'heroTitlePart3') {
            return isLocaleDe() ? 'wir tun' : 'we do';
        }
        if (key === 'heroContent') {
            return isLocaleDe()
                ? 'Frends hat mehr Funktionen als jede andere Integrationssoftware auf dem Markt. Erkunden Sie, entwickeln Sie Ideen und wenn etwas unklar ist, kontaktieren Sie uns jederzeit. Diese Bibliothek wird im Laufe der Zeit zwangsläufig wachsen.'
                : 'Frends has more features than any other integration software out there. Explore, ideate, and if anything is unclear - hit us up at any time. This library is bound to grow over time.';
        }
        return key;
    }

    return (
        <section className="hero is-fullheight">
            <GatsbyImage
                image={getImage(data.bg)}
                style={{ width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }}
                alt="" />
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-mobile">
                        <div className="column is-4-desktop is-hidden-mobile">
                            <PlatformMenu
                                platform={platform}
                                isHero={true}
                                color={color}
                                currentArticle={currentArticle}
                                currentCategory={currentCategory}
                                handleChangeArticle={handleChangeArticle}
                            />
                        </div>
                        <div className="column is-8-desktop is-12-mobile">
                            <h2 className="title font-bold size-100 is-white">
                                {getTranslation('heroTitlePart1')} <span className="font-variable font-width-variation-870">{getTranslation('heroTitlePart2')}</span>
                            </h2>
                            <h2 className="title font-bold size-100 is-transparent text-stroke-white">
                                {getTranslation('heroTitlePart3')}
                            </h2>
                            <p className="is-white size-20">
                                {getTranslation('heroContent')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export const Platform = ({
    platform,
    content,
    definedTermsPopUpItems,
    color,
    currentArticle,
    currentCategory,
    handleChangeArticle,
    locale
}) => {

    const platformContent = content.find((f) => slug(f.node.platformTitle) === currentArticle);

    var platformArticles = [];
    platform.forEach(category => {
        platformArticles = platformArticles.concat(category.node.platformArticles);
    });

    const nextArticle = getNextArticle(currentArticle, content, platformArticles);
    const prevArticle = getPrevArticle(currentArticle, content, platformArticles);

    return (
        <section className="hero is-fullheight is-orange-bg" id="platform-content">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-hidden-mobile">
                        <div className="column is-4">
                            <PlatformMenu
                                platform={platform}
                                color={color}
                                currentArticle={currentArticle}
                                currentCategory={currentCategory}
                                handleChangeArticle={handleChangeArticle}
                            />
                        </div>
                        <div className="column is-8">
                            <div className="platform-content is-orange content">
                                <ContentHTMLWithHoverPopUps
                                    contentHTML={platformContent.node.platformContent.childMarkdownRemark.html}
                                    popUpItems={definedTermsPopUpItems}
                                />
                            </div>
                            <Button
                                customStyles={{ margin: "10px 0", height: "50px" }}
                                textClass="size-20"
                                type="outlined"
                                text={locale === 'de' ? 'vorheriger Artikel' : 'previous article'}
                                color="orange"
                                onClick={() => handleChangeArticle(prevArticle.node.platform_category[0].categoryTitle, prevArticle.node.platformTitle)}
                            />
                            <Button
                                customStyles={{ margin: "10px 0", height: "50px", float: "right" }}
                                textClass="size-20"
                                type="outlined"
                                text={locale === 'de' ? 'nächster Artikel' : 'next article'}
                                color="green"
                                onClick={() => handleChangeArticle(nextArticle.node.platform_category[0].categoryTitle, nextArticle.node.platformTitle)}
                            />
                            <br />
                            <Button
                                customStyles={{ margin: "10px 0", height: "50px" }}
                                textClass="size-20"
                                type="outlined"
                                text={locale === 'de' ? 'Kontaktieren Sie uns' : 'contact us'}
                                color="purple"
                                to="/ask-us-anything"
                                outerLink={locale === 'de'}
                            />
                        </div>
                    </div>
                    <div className="columns is-hidden-tablet">
                        <div className="column is-12">
                            <div className="platform-content is-orange content">
                                <div dangerouslySetInnerHTML={{ __html: platformContent.node.platformContent.childMarkdownRemark.html }} />
                            </div>
                        </div>
                        <Button
                            customStyles={{ margin: "10px 0", height: "50px" }}
                            textClass="size-20"
                            type="outlined"
                            text={locale === 'de' ? 'vorheriger Artikel' : 'previous article'}
                            color="orange"
                            onClick={() => handleChangeArticle(prevArticle.node.platform_category[0].categoryTitle, prevArticle.node.platformTitle)}
                        />
                        <Button
                            customStyles={{ margin: "10px 0", height: "50px", float: "right" }}
                            textClass="size-20"
                            type="outlined"
                            text={locale === 'de' ? 'nächster Artikel' : 'next article'}
                            color="green"
                            onClick={() => handleChangeArticle(nextArticle.node.platform_category[0].categoryTitle, nextArticle.node.platformTitle)}
                        />
                        <br />
                        <Button
                            customStyles={{ margin: "10px 0", height: "50px" }}
                            textClass="size-20"
                            type="outlined"
                            text={locale === 'de' ? 'Kontaktieren Sie uns' : 'contact us'}
                            color="purple"
                            to="/ask-us-anything"
                            outerLink={locale === 'de'}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export const PlatformMenu = ({ platform, currentArticle, currentCategory, handleChangeArticle, color, isHero }) => {
    const [currentlyOpenedDropdown, setCurrentlyOpenedDropdown] = React.useState(null);
    const PlatformMenuItem = ({ plat, index, currentlyOpenedDropdown, setCurrentlyOpenedDropdown }) => {
        const match = plat.node.platformArticles && plat.node.platformArticles.some((s) => slug(s.platformTitle) === currentArticle);
        const [isOpen, setIsOpen] = React.useState();

        const props = useSpring({ transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)" });

        React.useEffect(() => {
            if (currentlyOpenedDropdown === null) {
                setIsOpen(currentCategory === slug(plat.node.categoryTitle))
            } else {
                setIsOpen(index === currentlyOpenedDropdown)
            }
        }, [currentlyOpenedDropdown]);

        const handleToggleOpened = () => isHero ? plat.node.platformArticles !== null && handleChangeArticle(plat.node.categoryTitle, plat.node.platformArticles[0].platformTitle) : setCurrentlyOpenedDropdown(index);

        return (
            <li className={`top-level-item is-${color}${isHero ? " is-hero" : ""}`}>
                <div className="top-level-title-container" onClick={handleToggleOpened} onKeyDown={handleToggleOpened} role="button" tabIndex={0} style={{ userSelect: "none" }}>
                    <span className={`top-level-title${isHero ? " is-white" : ""}`}>{plat.node.categoryTitle}</span>
                    {!isHero && <animated.i className="fas fa-chevron-down" style={props} />}
                </div>
                {!isHero && (
                    <ul className="font-regular">
                        {isOpen && plat.node.platformArticles && plat.node.platformArticles.map((article, idx) => (
                            <li className="article-item" key={idx} onClick={() => handleChangeArticle(plat.node.categoryTitle, article.platformTitle)}>
                                {match ? (
                                    <span className={`pointer size-20 article-title is-active${slug(article.platformTitle) === currentArticle ? ` menu-${color}` : ""}`}>{article.platformTitle}</span>
                                ) : (
                                    <span className="pointer size-20 article-title">{article.platformTitle}</span>
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </li>
        )
    }

    return (
        <aside className="menu platform-menu sticky">
            <ul className="menu-list">
                {platform.map((plat, i) => (
                    <PlatformMenuItem
                        key={i}
                        plat={plat}
                        index={i}
                        currentlyOpenedDropdown={currentlyOpenedDropdown}
                        setCurrentlyOpenedDropdown={setCurrentlyOpenedDropdown}
                    />
                ))}
            </ul>
        </aside>
    )
}

function getNextArticle(currentArticle, content, platformArticles) {
    var nextArticleIndex = platformArticles.indexOf(platformArticles.find((f) => slug(f.platformTitle) === currentArticle)) + 1;
    if (nextArticleIndex > platformArticles.length - 1) {
        nextArticleIndex = platformArticles.length - 1;
    }
    var nextArticle = slug(platformArticles[nextArticleIndex].platformTitle);

    var nextContentIndex = content.indexOf(content.find((f) => slug(f.node.platformTitle) === nextArticle));

    return content[nextContentIndex];
}

function getPrevArticle(currentArticle, content, platformArticles) {
    var prevArticleIndex = platformArticles.indexOf(platformArticles.find((f) => slug(f.platformTitle) === currentArticle)) - 1;
    if (prevArticleIndex < 0) {
        prevArticleIndex = 0;
    }
    var prevArticle = slug(platformArticles[prevArticleIndex].platformTitle);

    var prevContentIndex = content.indexOf(content.find((f) => slug(f.node.platformTitle) === prevArticle));

    return content[prevContentIndex];
}

Platform.propTypes = {
    edges: PropTypes.arrayOf({
        node: PropTypes.shape({
            categoryTitle: PropTypes.string,
            platformArticles: PropTypes.shape({
                platformTitle: PropTypes.string
            }).isRequired
        }).isRequired
    }),
    color: PropTypes.string.isRequired
}

export const query = graphql`query ($locale: String)  {
  allContentfulPlatform(
    sort: {fields: platform_category___categoryListOrder, order: ASC}
    filter: {node_locale: {eq: $locale}}
  ) {
    edges {
      node {
        platformTitle
        platformContent {
          childMarkdownRemark {
            html
          }
        }
        platform_category {
          categoryTitle
        }
      }
    }
  }
  allContentfulPlatformCategory(
    sort: {fields: categoryListOrder}
    filter: {node_locale: {eq: $locale}}
  ) {
    edges {
      node {
        categoryTitle
        platformArticles {
          platformTitle
        }
      }
    }
  }
  bg: file(relativePath: {eq: "images/orange_bg.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
