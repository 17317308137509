import React from "react";
import { graphql } from "gatsby";
import { useSpring, animated } from 'react-spring';

import Layout from "../components/layout/Layout";
import { PlatformMenu, Platform, PlatformHero, PlatformMobileMenu } from "./platform-index-template";
import { slug, getDefinedTermPopUpItem } from "../helpers";

export default ({ data, location, pageContext }) => {
    const [isPlatformCatalogueOpen, setIsPlatformCatalogueOpen] = React.useState(false);
    const spring = useSpring({ opacity: isPlatformCatalogueOpen ? 1 : 0, transform: isPlatformCatalogueOpen ? "translateY(0px)" : "translateY(1000px)" });

    const platformCategories = data.allContentfulPlatformCategory.edges;
    const content = data.allContentfulPlatform.edges;
    const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);
    const platformContext = data.contentfulPlatform;

    const [currentCategory, setCurrentCategory] = React.useState(platformContext.platform_category !== null ? slug(platformContext.platform_category[0].categoryTitle) : null);
    const [currentArticle, setCurrentArticle] = React.useState(slug(platformContext.platformTitle));
    const [currentArticleTitle, setCurrentArticleTitle] = React.useState(platformContext.platformTitle);

    const currentCategoryAsTitle = platformCategories.find((f) => f.node.platformArticles !== undefined && f.node.platformArticles.some((s) => s.platformTitle === currentArticleTitle)).node.categoryTitle;
    const documentTitle = `${currentCategoryAsTitle} - ${currentArticleTitle}`;

    const toggleCatalogue = () => setIsPlatformCatalogueOpen(isPlatformCatalogueOpen => !isPlatformCatalogueOpen);
    const handleChangeArticle = (category, article) => {
        setCurrentArticle(slug(article));
        setCurrentArticleTitle(article); // Used for getting the article title as un-slugged
        setCurrentCategory(slug(category));

        if (window && document) {
            window.history.pushState({}, "", `${pageContext.locale === 'de' ? '/de' : ''}/platform/${slug(article)}`)
            document.title = documentTitle;
        }

        const scrollToContent = () => {
            const content = document.getElementById("platform-content");
            if (!content) {
                window.requestAnimationFrame(scrollToContent);
            } else {
                content.scrollIntoView({ behavior: "smooth" });
            }
        }
        scrollToContent();
        setIsPlatformCatalogueOpen(false);
    }

    React.useEffect(() => {
        const scrollToContent = () => {
            const content = document.getElementById("platform-content");
            if (!content) {
                window.requestAnimationFrame(scrollToContent);
            } else {
                content.scrollIntoView({ behavior: "smooth" });
            }
        }
        setTimeout(() => scrollToContent(), 500);
    }, []);
    const seoData = {
        updatedAt: platformContext.updatedAt,
        createdAt: platformContext.createdAt,
        author: "Frends team",
        currentArticle: currentArticle,
        metaTags: platformContext.metaTags
    }
    return (
        <Layout
          title={documentTitle}
          transparentNavigation={true}
          isPlatform={true}
          seoData={seoData}
          location={location}
          language={pageContext.locale}
        >
            <div className="mobile-platform-catalogue-container is-hidden-tablet">
                <animated.div style={spring} className="platform-menu">
                    <PlatformMenu
                        platform={platformCategories}
                        color="orange"
                        currentArticle={currentArticle}
                        currentCategory={currentCategory}
                        handleChangeArticle={handleChangeArticle}
                    />
                </animated.div>

                <PlatformMobileMenu
                  locale={pageContext.locale}
                  onClick={toggleCatalogue}
                  isOpen={isPlatformCatalogueOpen}
                />
            </div>
            <main id="content" style={{ marginTop: "-80px" }}>
                <PlatformHero
                    platform={platformCategories}
                    color="orange"
                    currentArticle={currentArticle}
                    currentCategory={currentCategory}
                    handleChangeArticle={handleChangeArticle}
                    data={data}
                    locale={pageContext.locale}
                />
                <Platform
                    platform={platformCategories}
                    content={content}
                    definedTermsPopUpItems={definedTermsPopUpItems}
                    color="orange"
                    currentArticle={currentArticle}
                    currentCategory={currentCategory}
                    handleChangeArticle={handleChangeArticle}
                    locale={pageContext.locale}
                />
            </main>
        </Layout>
    )
}

export const query = graphql`query ($contentful_id: String!, $locale: String, $contentful_content_vocabulary_item_slugs: [String]) {
  contentfulPlatform(
    contentful_id: {eq: $contentful_id}
    node_locale: {eq: $locale}
  ) {
    metaTags
    createdAt
    updatedAt
    platformTitle
    platform_category {
      categoryTitle
    }
    platformContent {
      childMarkdownRemark {
        html
      }
    }
  }
  allContentfulPlatform(
    sort: {fields: platform_category___categoryListOrder, order: ASC}
    filter: {node_locale: {eq: $locale}}
  ) {
    edges {
      node {
        platformTitle
        platformContent {
          childMarkdownRemark {
            html
          }
        }
        platform_category {
          categoryTitle
        }
      }
    }
  }
  allContentfulPlatformCategory(
    sort: {fields: categoryListOrder}
    filter: {node_locale: {eq: $locale}}
  ) {
    edges {
      node {
        categoryTitle
        platformArticles {
          platformTitle
        }
      }
    }
  }
  bg: file(relativePath: {eq: "images/orange_bg.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allContentfulDefinedTerm(
    filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: $locale}}
    ) {
    nodes {
        slug
        title
        description {
            childMarkdownRemark {
                html
                excerpt
            }
        }
    }
  }
}
`
